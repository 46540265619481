import React, { useState } from "react";
import DatePickerField from "./DatePickerField";
import CustomInput from "./CustomInput";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/";
import "../styles/Regression.css";

import { Grid, Button, Container, Paper, LinearProgress } from "@mui/material";
import { TextField } from "formik-mui";

import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import { trackPromise } from "react-promise-tracker";

import { LoadingSpinnerComponent } from "./LoadingSpinnerComponent";

import Plot from "react-plotly.js";

import lodash from "lodash";

import axios from "axios";

/* This version of Regression.js contains the chart display as well!
 */

const regressionSchema = yup.object().shape({
  begDate: yup
    .date()
    .nullable()
    .typeError("Start date is required")
    .required("Start Date is required"),

  endDate: yup
    .date()
    .nullable()
    .when(
      "begDate",
      (begDate, yup) =>
        begDate && yup.min(begDate, "End date cannot be before start time")
    )
    .required("End Date is required")
    .typeError("Enter a value End date"),

  period: yup
    .number()
    .nullable()
    .typeError("Period is required")
    .required("Period is required"),
});

const Seasonality = () => {
  const [begDate, setBegDate] = useState("01-01-2016");
  const [endDate, setEndDate] = useState("");
  const [period, setPeriod] = useState("");
  const [df_season, setDf_season] = useState("");
  const [df_trend, setDf_trend] = useState("");
  const [df_resid, setDf_resid] = useState("");
  const [err, setErr] = useState("");
  const [plot, setPlot] = useState(0);
  const [prices, setPrices] = useState("empty");

  let obj = [];

  function POST(data_send) {
    trackPromise(
      /* fetch("/api/seasonality", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data_send),
      }) */
      axios
        .request({
          method: "POST",
          url: "https://neo-cycle-back-free.onrender.com/api/seasonality",
          /*             url: "http://127.0.0.1:5000",
           */
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(data_send),
        })
        //NOTE- for axios-if you try to console.log the response; the whole process will stall and fail. Really weird! Can use for testing, but if get stuck, just delete any console.log statements
        /* .then(function (res) {
          //handle success
          console.log("this is first response 0", res);
        }) */
        /*.then((res) => (res) => res.json())*/
        //.then((res) => JSON.stringify(res))
        .then((res) => JSON.stringify(res))
        .then(function (res) {
          //const obj = Object.keys(res).map((key) => [key, res[key]]);
          const obj = JSON.parse(res);
          console.log("this is obj", obj);
          const obj2 = Object.values(obj);
          console.log("this is obj2", obj2);

          //Turns object of objects into array of objects
          var result = obj2.map(function (e) {
            return Object.keys(e).map(function (k) {
              return e[k];
            });
          });
          //is this even necessary? https://stackoverflow.com/questions/42053773/how-to-convert-a-json-object-to-array-of-arrays
          console.log("this is result", res);
          var outputData = result[0].map(Object.values);
          //turns array of objects into array of arrays https://stackoverflow.com/questions/22477612/converting-array-of-objects-into-array-of-arrays
          console.log(outputData);

          setPrices(outputData);
        })

        .catch(function (error) {
          console.log(error);
        })
    );
  }

  /*   const dataprocessing = function () {
    const parsed = JSON.parse(df_season);
    console.log(parsed);
  }; */

  const onSubmit = async (values, actions) => {
    handleSubmit(values);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  };

  const handleSubmit = ({ begDate, endDate, period }) => {
    let data_send = {
      begDate: begDate,
      endDate: endDate,
      period: period,
    };
    setBegDate(begDate);
    setEndDate(endDate);
    setPeriod(period);
    console.log("this is data object", data_send);

    POST(data_send);
  };

  /*   const LoadingIndicator = (props) => {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress && <h1>Hey some async call in progress ! </h1>;
  };
 */

  //Below is the graphing for consumption
  var sliced1 = prices[0].slice(0, prices[0].length - 1); //will contain ['a', 'b', 'c']
  var sliced2 = prices[1].slice(0, prices[1].length - 1); //will contain ['a', 'b', 'c']
  var sliced3 = prices[2].slice(0, prices[2].length - 1); //will contain ['a', 'b', 'c']
  var sliced4 = prices[3].slice(0, prices[3].length - 1); //will contain ['a', 'b', 'c']
  var sliced5 = prices[4].slice(0, prices[4].length - 1); //will contain ['a', 'b', 'c']
  //slice gets range of items in an array

  var trace1 = {
    x: sliced5,
    y: sliced1,
    mode: "lines+markers",
    type: "scatter",
    name: "price",
  };

  var trace2 = {
    x: sliced5,
    y: sliced4,
    mode: "lines+markers",
    type: "scatter",
    name: "Trend",
  };

  var trace3 = {
    x: sliced5,
    y: sliced3,
    mode: "lines+markers",
    type: "scatter",
    name: "Seasonality",
  };

  var trace4 = {
    x: sliced5,
    y: sliced2,
    mode: "lines+markers",
    type: "scatter",
    name: "Residuals",
  };

  var data = [trace1, trace2, trace3, trace4];

  return (
    <div class="container-Sell">
      {/*       <h2 class="Headline">Enter regression variables</h2>
       */}{" "}
      <br></br>
      <Formik
        initialValues={{
          begDate: "",
          endDate: "",
          period: "",
          date: "",
        }}
        validationSchema={regressionSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          resetForm,
        }) => (
          <Form>
            {isSubmitting && <div>Submitting, please be patient...</div>}
            <Container
              style={{ background: "#1a202c" }}
              maxWidth="md"
              sx={{ height: "200%", minHeight: "200%", maxHeight: "200%" }}
            >
              <Grid container spacing={3} direction="column">
                <Grid item xs={12} align="center">
                  <h1>NEO Cyclical and Seasonality Finder</h1>
                </Grid>

                <Grid item xs={6} align="center">
                  <label htmlFor="begDate">Enter beginning date</label>
                  <br></br>
                  <DatePickerField
                    value={values.begDate}
                    name="begDate"
                    label="begDate"
                    selected={begDate}
                    onChange={handleChange("begDate")}
                    /*                   onBlur={handleBlur("begDate")}
                     */
                  />
                  {touched.begDate && errors.begDate && (
                    <div>{errors.begDate}</div>
                  )}
                </Grid>
                <Grid item xs={6} align="center">
                  <label htmlFor="endDate">Enter ending date</label>
                  <DatePickerField
                    value={values.endDate}
                    name="endDate"
                    label="endDate"
                    selected={endDate}
                    onChange={handleChange("endDate")}
                    /*                   onBlur={handleBlur("endDate")}
                     */
                  />
                  {touched.endDate && errors.endDate && (
                    <div>{errors.endDate}</div>
                  )}
                </Grid>
                <Grid item xs={6} align="center">
                  <label htmlFor="period">Enter period of cycle</label>
                  <br></br>
                  <Field
                    type="text"
                    value={values.period}
                    name="period"
                    label="period"
                    selected={period}
                    onChange={handleChange("period")}
                    /*                   onBlur={handleBlur("endDate")}
                     */
                  />
                  {touched.period && errors.period && (
                    <div>{errors.period}</div>
                  )}
                </Grid>
                {prices ? (
                  <Grid item xs={6} align="center">
                    <label htmlFor="message">
                      These are the Cyclical Decomposition charts:{" "}
                      <Paper elevation={4}>
                        <Plot
                          data={data} /* layout={plot.layout} */
                          responsive={true}
                          layout={{
                            width: 565,
                            height: 450,
                            x: 1.5,
                            xanchor: "right",
                            y: 1,
                            //title: "NEO Price",
                            showlegend: true,
                            legend: { orientation: "h" },
                            xaxis: {
                              // all "layout.xaxis" attributes: #layout-xaxis
                              title: "# of days", // more about "layout.xaxis.title": #layout-xaxis-title
                            },
                            yaxis: {
                              // all "layout.xaxis" attributes: #layout-xaxis
                              title: "$/USD", // more about "layout.xaxis.title": #layout-xaxis-title
                            },
                            updatemenus: [
                              {
                                y: 1,
                                yanchor: "top",
                                buttons: [
                                  {
                                    method: "restyle",
                                    args: ["visible", [true, true, true, true]],
                                    label: "All",
                                  },
                                  {
                                    method: "restyle",
                                    args: [
                                      "visible",
                                      [true, false, false, false],
                                    ],
                                    label: "Price",
                                  },
                                  {
                                    method: "restyle",
                                    args: [
                                      "visible",
                                      [false, true, false, false],
                                    ],
                                    label: "Trend",
                                  },
                                  {
                                    method: "restyle",
                                    args: [
                                      "visible",
                                      [false, false, true, false],
                                    ],
                                    label: "Cycle",
                                  },
                                  {
                                    method: "restyle",
                                    args: [
                                      "visible",
                                      [false, false, false, true],
                                    ],
                                    label: "Resid",
                                  },
                                ],
                              },
                            ],
                          }}
                        />
                      </Paper>
                      {/*                     {df_season ? { dataprocessing } : ""}
                       */}{" "}
                    </label>
                    <LoadingSpinnerComponent />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12} align="center">
                  <div id="buttons">
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                    <br></br>
                    <br></br>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Seasonality;
